import {
  CREATE_EMPLOYE,
  RETRIEVE_EMPLOYES,
  UPDATE_EMPLOYE,
  DELETE_EMPLOYE,
  DELETE_ALL_EMPLOYES
} from "../actions/types";

const initialState = [];

function employeReducer(employes = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case CREATE_EMPLOYE:
      return [...employes, payload];

    case RETRIEVE_EMPLOYES:
      return payload;

    case UPDATE_EMPLOYE:
      return employes.map((employe) => {
        if (employe.id === payload.id) {
          return {
            ...employe,
            ...payload,
          };
        } else {
          return employe;
        }
      });

    case DELETE_EMPLOYE:
      return employes.filter(({ id }) => id !== payload.id);

    case DELETE_ALL_EMPLOYES:
      return [];

    default:
      return employes;
  }
}

export default employeReducer;
