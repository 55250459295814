import {
CREATE_CHANTIER,
RETRIEVE_CHANTIERS,
UPDATE_CHANTIER,
DELETE_CHANTIER,
DELETE_ALL_CHANTIERS
} from "./types";

import ChantiersDataService from "../services/chantier.service";

export const createChantiers = (Nom_chantier,Numero_chantier) => async (dispatch) => {
  try {
    const res = await ChantiersDataService.create({ Nom_chantier,Numero_chantier});

    dispatch({
      type: CREATE_CHANTIER,
      payload: res.data,
    });

    return Promise.resolve(res.data);
  } catch (err) {
    return Promise.reject(err);
  }
};


export const retrieveChantiers = () => async (dispatch) => {

  try {
    const res = await ChantiersDataService.getAll();

    dispatch({
      type: RETRIEVE_CHANTIERS,
      payload: res.data,

    });
  } catch (err) {
    console.log(err);
  }
};

export const updateChantier = (id, data) => async (dispatch) => {
  try {
    const res = await ChantiersDataService.update(id, data);

    dispatch({
      type: UPDATE_CHANTIER,
      payload: data,
    });

    return Promise.resolve(res.data);
  } catch (err) {
    return Promise.reject(err);
  }
};

export const deleteChantier = (id) => async (dispatch) => {
  try {
    await ChantiersDataService.delete(id);

    dispatch({
      type: DELETE_CHANTIER,
      payload: { id },
    });
  } catch (err) {
    console.log(err);
  }
};

export const deleteAllChantiers = () => async (dispatch) => {
  try {
    const res = await ChantiersDataService.deleteAll();

    dispatch({
      type: DELETE_ALL_CHANTIERS,
      payload: res.data,
    });

    return Promise.resolve(res.data);
  } catch (err) {
    return Promise.reject(err);
  }
};

export const findChantiersByTitle = (nom) => async (dispatch) => {
  try {
    const res = await ChantiersDataService.findByTitle(nom);

    dispatch({
      type: RETRIEVE_CHANTIERS,
      payload: res.data
    });
  } catch (err) {
    console.log(err);
  }
};
