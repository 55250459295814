import React, { Component } from "react";
import { connect } from "react-redux";
import {createChantiers} from "../actions/chantier";
import ChantiersDataService from "../services/chantier.service";

class AddChantier extends Component {
  constructor(props) {
    super(props);
    this.onChangeNom = this.onChangeNom.bind(this);
    this.onChangeNumero=this.onChangeNumero.bind(this);

    this.newChantier = this.newChantier.bind(this);
    this.saveChantier=this.saveChantier.bind(this);

    this.state = {
      id: null,
      Nom: "",
      Numero_chantier:"",

      submitted: false,
      message: "",

    };
  }


  onChangeNom(e) {
    this.setState({
      Nom: e.target.value,
    });
  }

  onChangeNumero(e) {
    this.setState({
      Numero_chantier: e.target.value,
    });
  }

  newChantier() {
    this.setState({
      id:null,
      Nom: "",
      Numero_chantier:"",

    });
  }

    saveChantier() {
      const data = {
        Nom: this.state.Nom,
        Numero_chantier: this.state.Numero_chantier,

      };

      ChantiersDataService.create(data)
          .then(response => {
            this.setState({
              id: response.data.id,
              Nom: response.data.Nom,
              Numero_chantier: response.data.Numero_chantier,

              published: response.data.published,

              submitted: true
            });
            console.log(response.data);
          })
          .catch(e => {
            console.log(e);
          });
    }



  render() {
    const {message
    } = this.state;

    return (
        <div>
        <div>

          <div className="alert alert-light" role="alert">
            <p>{message}</p>
          </div>
        </div>

    <div className="submit-form">
      {this.state.submitted ? (
          <div>
            <h4>You submitted successfully!</h4>
            <a className="btn btn-success" onClick={this.newChantier} href="https://pointage.ansart-tp.com/chantiers">
              Revenir à la liste des chantiers
            </a>
          </div>
      ) : (
          <div>
            <div className="form-group">
              <label htmlFor="Nom">Nom du chantier </label>
              <input
                  type="text"
                  className="form-control"
                  id="Nom_chantier"
                  required
                  value={this.state.Nom}
                  onChange={this.onChangeNom}
                  name="Nom"
              />
              <label htmlFor="Numero">Numero de chantier</label>
              <input
                  type="text"
                  className="form-control"
                  id="Prenom"
                  required
                  value={this.state.Numero_chantier}
                  onChange={this.onChangeNumero}
                  name="Numero de chantier"
              />



            </div>

            <button onClick={this.saveChantier} className="btn btn-success">
              Ajouter
            </button>
          </div>
      )}
    </div>

        </div>
    );
  }
}


export default connect(null, { createChantiers })(AddChantier);


