import http from "../../http-common";

class DocumentsDataService {



  getAll() {
    return http.get("/ansart/documents");
  }

  get(id) {
    return http.get(`/ansart/documents/${id}`);
  }

  create(data) {
    return http.post("/ansart/documents", data);

  }

  update(id, data) {
    return http.put(`/ansart/documents/${id}`, data);
  }

  delete(id) {
    return http.delete(`/ansart/documents/${id}`);
  }

  deleteAll() {
    return http.delete(`/ansart/documents`);
  }

  findByTitle(Nom) {
    return http.get(`/ansart/documents?nom=${Nom}`);
  }
}

export default new DocumentsDataService();
