import React, { Component } from "react";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";
import { connect } from "react-redux";



import AddEmploye from "./Employe/components/add-employes.component";
import Employe from "./Employe/components/employe.component";
import EmployesList from "./Employe/components/employes-list.component";

import AddChantier from "./Chantier/components/add-chantiers.component";
import Chantier from "./Chantier/components/chantier.component";
import ChantiersList from "./Chantier/components/chantiers-list.component";

import AddMateriel from "./Materiel/components/add-materiels.component";
import Materiel from "./Materiel/components/materiel.component";
import MaterielsList from "./Materiel/components/materiels-list.component";



import AddTheme from "./Theme/components/add-themes.component";
import Theme from "./Theme/components/theme.component";
import ThemesList from "./Theme/components/themes-list.component";


import AddDocument from "./Document/components/add-documents.component";
import Document from "./Document/components/document.component";
import DocumentsList from "./Document/components/documents-list.component";


import Login from "./Session/components/login.component";

import { clearMessage } from "./Session/actions/message";

import { history } from './Session/helpers/history';
import authHeader from "./Session/services/auth-header";




class App extends Component {

  constructor(props) {
    super(props);

    this.state = {
      showModeratorBoard: false,
      showAdminBoard: false,
      currentUser: undefined,
    };

    history.listen((location) => {
      props.dispatch(clearMessage()); // clear message when changing location
    });
  }

  componentDidMount() {
    const user = this.props.user;

    if (user) {
      this.setState({
        currentUser: user,
        showModeratorBoard: user.roles.includes("ROLE_MODERATOR"),
        showAdminBoard: user.roles.includes("ROLE_ADMIN"),
        headers: authHeader()
      })
      console.log(user);

      var hours = 1; // Reset when storage is more than 24hours
      var now = new Date().getTime();
      var setupTime = localStorage.getItem('setupTime');
      if (setupTime == null) {
        localStorage.setItem('setupTime', now)
      }
      if (now - setupTime > hours * 60 * 60 * 1000) {
        localStorage.clear();
        localStorage.setItem('setupTime', now);
        window.location.reload("/");
      }
    }

  }




  render() {
    const { showAdminBoard } = this.state;
    return (
      <Router history={history}>
        <nav className="navbar navbar-expand navbar-dark bg-dark">
          <Link to={"/login"} className="navbar-brand">
           Gestion pointage
          </Link>
          {showAdminBoard && (
          <div className="navbar-nav mr-auto">

            <li className="nav-item">
              <Link to={"/employes"} className="nav-link">
                Employés
              </Link>
            </li>

            <li className="nav-item">
              <Link to={"/chantiers"} className="nav-link">
                Chantiers
              </Link>
            </li>

            <li className="nav-item">
              <Link to={"/materiels"} className="nav-link">
                Matériel
              </Link>
            </li>

            <li className="nav-item">
              <Link to={"/themes"} className="nav-link">
                Thèmes
              </Link>
            </li>

            <li className="nav-item">
              <Link to={"/documents"} className="nav-link">
                Documents
              </Link>
            </li>

          </div>
          )}
        </nav>

        <div className="container mt-3">
          <Switch>





            <Route exact path= "/employes" component={EmployesList} />
            <Route exact path="/addemployes" component={AddEmploye} />
            <Route path="/employes/:id" component={Employe} />

            <Route exact path= "/chantiers" component={ChantiersList} />
            <Route exact path="/addchantiers" component={AddChantier} />
            <Route path="/chantiers/:id" component={Chantier} />


            <Route exact path= "/materiels" component={MaterielsList} />
            <Route exact path="/addmateriels" component={AddMateriel} />
            <Route path="/materiels/:id" component={Materiel} />

            <Route exact path= "/themes" component={ThemesList} />
            <Route exact path="/addthemes" component={AddTheme} />
            <Route path="/themes/:id" component={Theme} />


            <Route exact path= "/documents" component={DocumentsList} />
            <Route exact path="/adddocuments" component={AddDocument} />
            <Route path="/documents/:id" component={Document} />


            <Route exact path={["/", "/login"]} component={Login} />




          </Switch>
        </div>
      </Router>
    );
  }
}

function mapStateToProps(state) {
  const { user } = state.auth;
  return {
    user,
  };
}
export default connect(mapStateToProps)(App);

