import http from "../../http-common";

class EmployesDataService {



  getAll() {
    return http.get("/ansart/employes");
  }

  get(id) {
    return http.get(`/ansart/employes/${id}`);
  }

  create(data) {
    return http.post("/ansart/employes", data);

  }

  update(id, data) {
    return http.put(`/ansart/employes/maj`, data);
  }

  delete(id) {
    return http.delete(`/ansart/employes/${id}`);
  }

  deleteAll() {
    return http.delete(`/ansart/employes`);
  }

  findByTitle(Nom) {
    return http.get(`/ansart/employes?nom=${Nom}`);
  }
}

export default new EmployesDataService();
