import React, { Component } from "react";
import { connect } from "react-redux";
import {createDocuments} from "../actions/document";
import DocumentsDataService from "../services/document.service";

class AddDocument extends Component {
  constructor(props) {
    super(props);
    this.onChangeNom = this.onChangeNom.bind(this);

    this.newDocument = this.newDocument.bind(this);
    this.saveDocument=this.saveDocument.bind(this);

    this.state = {
      id: null,
      Nom: "",
      submitted: false,
      message: "",

    };
  }


  onChangeNom(e) {
    this.setState({
      Nom: e.target.value,
    });
  }



  newDocument() {
    this.setState({
      id:null,
      Nom: "",

    });
  }

    saveDocument() {
      const data = {
        Nom: this.state.Nom,

      };

      DocumentsDataService.create(data)
          .then(response => {
            this.setState({
              id: response.data.id,
              Nom: response.data.Nom,

              published: response.data.published,

              submitted: true
            });
            console.log(response.data);
          })
          .catch(e => {
            console.log(e);
          });
    }



  render() {
    const {message
    } = this.state;

    return (
        <div>
        <div>

          <div className="alert alert-light" role="alert">
            <p>{message}</p>
          </div>
        </div>

    <div className="submit-form">
      {this.state.submitted ? (
          <div>
            <h4>You submitted successfully!</h4>
            <a className="btn btn-success" onClick={this.newDocument} href="https://pointage.ansart-tp.com/documents">
              Revenir à la liste des documents
            </a>
          </div>
      ) : (
          <div>
            <div className="form-group">
              <label htmlFor="Nom">Nom du document</label>
              <input
                  type="text"
                  className="form-control"
                  id="Nom"
                  required
                  value={this.state.Nom}
                  onChange={this.onChangeNom}
                  name="Nom"
              />



            </div>

            <button onClick={this.saveDocument} className="btn btn-success">
              Ajouter
            </button>
          </div>
      )}
    </div>

        </div>
    );
  }
}


export default connect(null, { createDocuments })(AddDocument);


