import React, { Component } from "react";
import { connect } from "react-redux";
import {
retrieveThemes,findThemesByTitle,deleteAllThemes
} from "../actions/theme";
import { Link } from "react-router-dom";

class ChantiersList extends Component {
  constructor(props) {
    super(props);
    this.onChangeSearchNom = this.onChangeSearchNom.bind(this);
    this.refreshData = this.refreshData.bind(this);
    this.setActiveTheme = this.setActiveTheme.bind(this);
    this.findByNom = this.findByNom.bind(this);
    this.removeAllThemes = this.removeAllThemes.bind(this);

    this.state = {
      currentTheme: null,
      currentIndex: -1,
      searchNom: "",
    };
  }

  componentDidMount() {
    this.props.retrieveThemes();

  }

  onChangeSearchNom(e) {
    const searchNom = e.target.value;

    this.setState({
      searchNom: searchNom,
    });
  }

  refreshData() {
    this.setState({
      currentTheme: null,
      currentIndex: -1,
    });
  }

  setActiveTheme(theme, index) {
    this.setState({
      currentTheme: theme,
      currentIndex: index,
    });
  }

  removeAllThemes() {
    this.props
      .deleteAllThemes()
      .then((response) => {
        console.log(response);
        this.refreshData();
      })
      .catch((e) => {
        console.log(e);
      });
  }

  findByNom() {
    this.refreshData();

    this.props.findThemesByTitle(this.state.searchNom);
  }

  render() {
    const { searchNom, currentTheme, currentIndex } = this.state;
    const { themes } = this.props;


    return (
      <div className="list row">
        <div className="col-md-8">
          <div className="input-group mb-3">
            <input
              type="text"
              className="form-control"
              placeholder="Rechercher un thème"
              value={searchNom}
              onChange={this.onChangeSearchNom}
            />

            <div className="input-group-append">
              <button
                className="btn btn-outline-secondary"
                type="button"
                onClick={this.findByNom}
              >
                Rechercher
              </button>

            </div>

          </div>
        </div>
        <div className="col-md-6">
          <h4>Liste des thèmes</h4>

          <ul className="list-group">

            {themes &&
            themes.map((theme, index) => (

                  <li
                  className={
                    "list-group-item " +
                    (index === currentIndex ? "active" : "")
                  }

                  onClick={() => this.setActiveTheme(theme)}
                  key={theme.id}

                >
                    <ul> {theme.Nom}      </ul>
                </li>


              ))}
          </ul>

          <button
            className="m-3 btn btn-sm btn-danger"
            onClick={this.removeAllThemes}
          >
            Supprimer tous les thèmes
          </button>
        </div>
        <div className="col-md-6">
          {currentTheme ? (
            <div>
              <h4>Thème</h4>
              <div>
                <label>
                  <strong>Nom:</strong>
                </label>{" "}
                {currentTheme.Nom}
              </div>
              <div>
                <label>
                  <strong>Status:</strong>
                </label>{" "}
                {currentTheme.published ? "Published" : "Pending"}
              </div>

              <Link
                to={"/themes/" + currentTheme.id}
                className="badge badge-warning"
              >
                Editer
              </Link>
            </div>
          ) : (
            <div>
              <br />
              <p>Cliquez sur un thème ou  </p>
              <p>  <a className="nav-item"
                            href="https://pointage.ansart-tp.com/addthemes" type="button"> Ajouter un thème </a>
              </p>
            </div>
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    themes: state.themes,
  };
};

export default connect(mapStateToProps, {
  retrieveThemes,findThemesByTitle,deleteAllThemes
})(ChantiersList);
