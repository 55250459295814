import {
  CREATE_MATERIEL,
  RETRIEVE_MATERIELS,
  UPDATE_MATERIEL,
  DELETE_MATERIEL,
  DELETE_ALL_MATERIELS
} from "../actions/types";

const initialState = [];

function materielReducer(materiels = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case CREATE_MATERIEL:
      return [...materiels, payload];

    case RETRIEVE_MATERIELS:
      return payload;

    case UPDATE_MATERIEL:
      return materiels.map((materiel) => {
        if (materiel.id === payload.id) {
          return {
            ...materiel,
            ...payload,
          };
        } else {
          return materiel;
        }
      });

    case DELETE_MATERIEL:
      return materiels.filter(({ id }) => id !== payload.id);

    case DELETE_ALL_MATERIELS:
      return [];

    default:
      return materiels;
  }
}

export default materielReducer;
