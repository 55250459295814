import http from "../../http-common";

class MaterielsDataService {



  getAll() {
    return http.get("/ansart/materiels");
  }

  get(id) {
    return http.get(`/ansart/materiels/${id}`);
  }

  create(data) {
    return http.post("/ansart/materiels", data);

  }

  update(id, data) {
    return http.put(`/ansart/materiels/${id}`, data);
  }

  delete(id) {
    return http.delete(`/ansart/materiels/${id}`);
  }

  deleteAll() {
    return http.delete(`/ansart/materiels`);
  }

  findByTitle(Nom) {
    return http.get(`/ansart/materiels?nom=${Nom}`);
  }
}

export default new MaterielsDataService();
