import {
  CREATE_DOCUMENT,
  RETRIEVE_DOCUMENTS,
  UPDATE_DOCUMENT,
  DELETE_DOCUMENT,
  DELETE_ALL_DOCUMENTS
} from "../actions/types";

const initialState = [];

function documentReducer(documents = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case CREATE_DOCUMENT:
      return [...documents, payload];

    case RETRIEVE_DOCUMENTS:
      return payload;

    case UPDATE_DOCUMENT:
      return documents.map((document) => {
        if (documents.id === document.id) {
          return {
            ...document,
            ...payload,
          };
        } else {
          return document;
        }
      });

    case DELETE_DOCUMENT:
      return documents.filter(({ id }) => id !== payload.id);

    case DELETE_ALL_DOCUMENTS:
      return [];

    default:
      return documents;
  }
}

export default documentReducer;
