import {
  CREATE_CHANTIER,
  RETRIEVE_CHANTIERS,
  UPDATE_CHANTIER,
  DELETE_CHANTIER,
  DELETE_ALL_CHANTIERS
} from "../actions/types";

const initialState = [];

function chantierReducer(chantiers = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case CREATE_CHANTIER:
      return [...chantiers, payload];

    case RETRIEVE_CHANTIERS:
      return payload;

    case UPDATE_CHANTIER:
      return chantiers.map((chantier) => {
        if (chantier.id === chantier.id) {
          return {
            ...chantier,
            ...payload,
          };
        } else {
          return chantier;
        }
      });

    case DELETE_CHANTIER:
      return chantiers.filter(({ id }) => id !== payload.id);

    case DELETE_ALL_CHANTIERS:
      return [];

    default:
      return chantiers;
  }
}

export default chantierReducer;
