import http from "../../http-common";

class ThemesDataService {



  getAll() {
    return http.get("/ansart/themes");
  }

  get(id) {
    return http.get(`/ansart/themes/${id}`);
  }

  create(data) {
    return http.post("/ansart/themes", data);

  }

  update(id, data) {
    return http.put(`/ansart/themes/${id}`, data);
  }

  delete(id) {
    return http.delete(`/ansart/themes/${id}`);
  }

  deleteAll() {
    return http.delete(`/ansart/themes`);
  }

  findByTitle(Nom) {
    return http.get(`/ansart/themes?nom=${Nom}`);
  }
}

export default new ThemesDataService();
