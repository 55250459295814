import http from "../../http-common";

class ChantiersDataService {



  getAll() {
    return http.get("/ansart/chantiers");
  }

  get(id) {
    return http.get(`/ansart/chantiers/${id}`);
  }

  create(data) {
    return http.post("/ansart/chantiers", data);

  }

  update(id, data) {
    return http.put(`/ansart/chantiers/${id}`, data);
  }

  delete(id) {
    return http.delete(`/ansart/chantiers/${id}`);
  }

  deleteAll() {
    return http.delete(`/ansart/chantiers`);
  }

  findByTitle(Nom) {
    return http.get(`/ansart/chantiers?nom=${Nom}`);
  }
}

export default new ChantiersDataService();
