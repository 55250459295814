import React, { Component } from "react";
import { connect } from "react-redux";
import { updateTheme, deleteTheme } from "../actions/theme";
import ThemesDataService from "../services/theme.service";

class Theme extends Component {
  constructor(props) {
    super(props);
    this.onChangeNom = this.onChangeNom.bind(this);

    this.getTheme = this.getTheme.bind(this);
    this.removeTheme = this.removeTheme.bind(this);
    this.updateTheme=this.updateTheme.bind(this);
    this.updatePublished=this.updatePublished.bind(this);

    this.state = {
      currentTheme: {
        id: null,
        Nom: "",

        published:false
      },

    };
  }

  componentDidMount() {
    this.getTheme(this.props.match.params.id);
  }

  onChangeNom(e) {
    const Nom = e.target.value;

    this.setState(function (prevState) {
      return {
        currentTheme: {
          ...prevState.currentTheme,
          Nom: Nom,
        },
      };
    });
  }




  updatePublished(status) {
    var data = {
      id: this.state.currentTheme.id,
      Nom: this.state.currentTheme.Nom,

    };

    ThemesDataService.update(
        this.state.currentTheme.id,
        data
    )
        .then(response => {
          this.setState(prevState => ({
            currentTheme: {
              ...prevState.currentTheme,
              published: true
            }
          }));
          console.log(response.data);
        })
        .catch(e => {
          console.log(e);
        });
  }

  updateTheme() {
    ThemesDataService.update(
        this.state.currentTheme.id,
        this.state.currentTheme
    )
        .then(response => {
          console.log(response.data);
          this.setState({
            message: "La mise à jour a été effectué",
          });
        }).then(response => {
      this.setState(prevState => ({
        currentTheme: {
          ...prevState.currentTheme,
          published: true
        }
      }));
      console.log(response.data);
    })
        .catch(e => {
          console.log(e);
        });
  }

  getTheme(id) {
    ThemesDataService.get(id)
      .then((response) => {
        this.setState({
          currentTheme: response.data,
        });
        console.log(response.data);
      })
      .catch((e) => {
        console.log(e);
      });
  }



  removeTheme() {
    this.props
      .deleteTheme(this.state.currentTheme.id)
      .then(() => {
        this.props.history.push("/themes");
      })
      .catch((e) => {
        console.log(e);
      });
  }

  render() {
    const { currentTheme,message } = this.state;

    return (
      <div>
        {currentTheme ? (
            <div>
              {!currentTheme.published && (
            <div>
          <div className="edit-form">
            <div>
            <h4>Mise à jour de : {currentTheme.Nom}, {currentTheme.Prenom}</h4>
            <form>
              <div className="form-group">
                <label htmlFor="Nom">Nom</label>
                <input
                    type="text"
                    className="form-control"
                    id="Nom"
                    value={currentTheme.Nom}
                    onChange={this.onChangeNom}
                />


              </div>
              <div>

                <div className="alert alert-light" role="alert">
                  {message}
                </div>


              </div>

              <div className="form-group">
                <label>
                  <strong>Status:</strong>
                </label>
                {currentTheme.published ? "Published" : "En cours de modification"}
              </div>
            </form>

          </div>

            <button
              className="badge badge-danger mr-2"
              onClick={this.removeTheme}
            >
              Supprimer
            </button>
            <a
                className="btn badge-success"
                onClick={this.updateTheme}
            >
              Mettre à jour
            </a>
          </div>

            </div>   )}
            {currentTheme.published===true && (
                <div>
                  <a
                      href="https://pointage.ansart-tp.com/themes/"
                  >
                    Revenir à la liste des thèmes
                  </a>

                </div>)}

          </div>

        ) : (
          <div>
            <br />
            <p>Erreur ce thème n'éxiste pas ou n'est pas dans la base de données</p>
            <a
                href="https://pointage.ansart-tp.com/thèmes/"
            >
              Revenir à la liste des thèmes
            </a>
          </div>
        )
        }
      </div>

    );
  }
}

export default connect(null, {updateTheme,deleteTheme })(Theme);
