import React, { Component } from "react";
import { connect } from "react-redux";
import {createThemes} from "../actions/theme";
import ThemesDataService from "../services/theme.service";

class AddTheme extends Component {
  constructor(props) {
    super(props);
    this.onChangeNom = this.onChangeNom.bind(this);

    this.newTheme = this.newTheme.bind(this);
    this.saveTheme=this.saveTheme.bind(this);

    this.state = {
      id: null,
      Nom: "",
      submitted: false,
      message: "",

    };
  }


  onChangeNom(e) {
    this.setState({
      Nom: e.target.value,
    });
  }



  newTheme() {
    this.setState({
      id:null,
      Nom: "",

    });
  }

    saveTheme() {
      const data = {
        Nom: this.state.Nom,

      };

      ThemesDataService.create(data)
          .then(response => {
            this.setState({
              id: response.data.id,
              Nom: response.data.Nom,

              published: response.data.published,

              submitted: true
            });
            console.log(response.data);
          })
          .catch(e => {
            console.log(e);
          });
    }



  render() {
    const {message
    } = this.state;

    return (
        <div>
        <div>

          <div className="alert alert-light" role="alert">
            <p>{message}</p>
          </div>
        </div>

    <div className="submit-form">
      {this.state.submitted ? (
          <div>
            <h4>You submitted successfully!</h4>
            <a className="btn btn-success" onClick={this.newTheme} href="https://pointage.ansart-tp.com/themes">
              Revenir à la liste des thèmes
            </a>
          </div>
      ) : (
          <div>
            <div className="form-group">
              <label htmlFor="Nom">Nom du thème </label>
              <input
                  type="text"
                  className="form-control"
                  id="Nom"
                  required
                  value={this.state.Nom}
                  onChange={this.onChangeNom}
                  name="Nom"
              />



            </div>

            <button onClick={this.saveTheme} className="btn btn-success">
              Ajouter
            </button>
          </div>
      )}
    </div>

        </div>
    );
  }
}


export default connect(null, { createThemes })(AddTheme);


