import { combineReducers } from "redux";
import employes from "../../Employe/reducers/employes";
import chantiers from "../../Chantier/reducers/chantiers"
import themes from "../../Theme/reducers/themes"
import auth from "../../Session/reducers/auth";
import message from "../../Session/reducers/message";
import materiels from "../../Materiel/reducers/materiels";
import documents from "../../Document/reducers/documents";


export default combineReducers({

  employes,
  auth,
  message,
  chantiers,
  materiels,
  themes,
  documents

});
