import React, { Component } from "react";
import { connect } from "react-redux";
import { updateEmploye, deleteEmploye } from "../actions/employe";
import EmployesDataService from "../services/employe.service";

class Employe extends Component {
  constructor(props) {
    super(props);
    this.onChangeNom = this.onChangeNom.bind(this);
    this.onChangePrenom=this.onChangePrenom.bind(this);
    this.onChangeEmployeur=this.onChangeEmployeur.bind(this);
    this.onChangeMail = this.onChangeMail.bind(this);
    this.onChangeNationalite=this.onChangeNationalite.bind(this);
    this.onChangeNaissance=this.onChangeNaissance.bind(this);
    this.onChangeDureeNuit=this.onChangeDureeNuit.bind(this);
    this.onChangeDureeJour=this.onChangeDureeJour.bind(this);
    this.onChangeDureeDimanche=this.onChangeDureeDimanche.bind(this);
    this.onChangeDureeTotal=this.onChangeDureeTotal.bind(this);
    this.getEmploye = this.getEmploye.bind(this);
    this.removeEmploye = this.removeEmploye.bind(this);
    this.updateEmploye=this.updateEmploye.bind(this);
    this.updatePublished=this.updatePublished.bind(this);

    this.state = {
      currentEmploye: {
        id: null,
        Nom: "",
        Prenom:"",
        Employeur: "",
        Mail:"",
        Naissance:'',
        Nationalite: "",
        published:false,
        DureeTotal:'',
      },

    };
  }

  componentDidMount() {
    this.getEmploye(this.props.match.params.id);
  }

  onChangeNom(e) {
    const Nom = e.target.value;

    this.setState(function (prevState) {
      return {
        currentEmploye: {
          ...prevState.currentEmploye,
          Nom: Nom,
        },
      };
    });
  }

  onChangeNaissance(e) {
    const Naissance = e.target.value;

    this.setState(function (prevState) {
      return {
        currentEmploye: {
          ...prevState.currentEmploye,
          Naissance: Naissance,
        },
      };
    });
  }
  onChangeNationalite(e) {
    const Nationalite = e.target.value;

    this.setState(function (prevState) {
      return {
        currentEmploye: {
          ...prevState.currentEmploye,
          Nationalite: Nationalite,
        },
      };
    });
  }

  onChangeDureeNuit(e) {
    const DureeNuit = e.target.value;

    this.setState(function (prevState) {
      return {
        currentEmploye: {
          ...prevState.currentEmploye,
          DureeNuit: DureeNuit,
        },
      };
    });
  }

  onChangeDureeJour(e) {
    const DureeJour = e.target.value;

    this.setState(function (prevState) {
      return {
        currentEmploye: {
          ...prevState.currentEmploye,
          DureeJour: DureeJour,
        },
      };
    });
  }

  onChangeDureeDimanche(e) {
    const DureeDimanche = e.target.value;

    this.setState(function (prevState) {
      return {
        currentEmploye: {
          ...prevState.currentEmploye,
          DureeDimanche: DureeDimanche,
        },
      };
    });
  }
  onChangeDureeTotal(e) {
    const DureeTotal = e.target.value;

    this.setState(function (prevState) {
      return {
        currentEmploye: {
          ...prevState.currentEmploye,
          DureeTotal: DureeTotal,
        },
      };
    });
  }

  onChangeMail(e) {
    const Mail = e.target.value;

    this.setState(function (prevState) {
      return {
        currentEmploye: {
          ...prevState.currentEmploye,
          Mail: Mail,
        },
      };
    });
  }

  onChangePrenom(e) {
    const Prenom = e.target.value;

    this.setState(function (prevState) {
      return {
        currentEmploye: {
          ...prevState.currentEmploye,
          Prenom: Prenom,
        },
      };
    });
  }

  onChangeEmployeur(e) {
    const Employeur = e.target.value;

    this.setState(function (prevState) {
      return {
        currentEmploye: {
          ...prevState.currentEmploye,
          Employeur: Employeur,
        },
      };
    });
  }
  updatePublished(status) {
    var data = {
      id: this.state.currentEmploye.id,
      Nom: this.state.currentEmploye.Nom,
      Prenom: this.state.currentEmploye.Prenom,
      Employeur: this.state.currentEmploye.Employeur,
      Mail:this.state.currentEmploye.Mail,
      Naissance:this.state.currentEmploye.Naissance,
      Nationalite:this.state.currentEmploye.Nationalite,
      published: status
    };

    EmployesDataService.update(
        this.state.currentEmploye.id,
        data
    )
        .then(response => {
          this.setState(prevState => ({
            currentEmploye: {
              ...prevState.currentEmploye,
              published: status
            }
          }));
          console.log(response.data);
        })
        .catch(e => {
          console.log(e);
        });
  }

  updateEmploye() {
    EmployesDataService.update(
        this.state.currentEmploye.id,
        this.state.currentEmploye
    )
        .then(response => {
          console.log(response.data);
          this.setState({
            message: "La mise à jour a bien été effecuté!"
          });
        }).then(response => {
      this.setState(prevState => ({
        currentEmploye: {
          ...prevState.currentEmploye,
          published: true
        }
      }));
      console.log(response.data);
    })
        .catch(e => {
          console.log(e);
        });
  }

  getEmploye(id) {
    EmployesDataService.get(id)
      .then((response) => {
        this.setState({
          currentEmploye: response.data,
        });
        console.log(response.data);
      })
      .catch((e) => {
        console.log(e);
      });
  }





  removeEmploye() {
    this.props
      .deleteEmploye(this.state.currentEmploye.id)
      .then(() => {
        this.props.history.push("/employes");
      })
      .catch((e) => {
        console.log(e);
      });
  }

  render() {
    const { currentEmploye,message } = this.state;

    return (
      <div>
        {currentEmploye ? (
            <div>
              {!currentEmploye.published && (
            <div>
          <div className="edit-form">
            <div>
            <h4>Mise à jour de : {currentEmploye.Nom}, {currentEmploye.Prenom}</h4>
            <form>
              <div className="form-group">
                <label htmlFor="Nom">Nom</label>
                <input
                    type="text"
                    className="form-control"
                    id="Nom"
                    value={currentEmploye.Nom}
                    onChange={this.onChangeNom}
                />

                <label htmlFor="Prenom">Prenom</label>
                <input
                    type="text"
                    className="form-control"
                    id="Prenom"
                    value={currentEmploye.Prenom}
                    onChange={this.onChangePrenom}
                />
                <label htmlFor="Employeur">Employeur</label>
                <input
                    type="text"
                    className="form-control"
                    id="Employeur"
                    value={currentEmploye.Employeur}
                    onChange={this.onChangeEmployeur}
                />

                <label htmlFor="Naissance">Date de naissance</label>
                <input
                    type="text"
                    className="form-control"
                    id="Naissance"
                    value={currentEmploye.Naissance}
                    onChange={this.onChangeNaissance}
                />

                <label htmlFor="Nationalite">Nationalité</label>
                <input
                    type="text"
                    className="form-control"
                    id="Nationalite"
                    value={currentEmploye.Nationalite}
                    onChange={this.onChangeNationalite}
                />

                <label htmlFor="Mail">Mail</label>
                <input
                    type="text"
                    className="form-control"
                    id="Mail"
                    value={currentEmploye.Mail}
                    onChange={this.onChangeMail}
                />

                <label htmlFor="DureeNuit">Duree Nuit</label>
                <input
                    type="text"
                    className="form-control"
                    id="Mail"
                    value={currentEmploye.DureeNuit}
                    onChange={this.onChangeDureeNuit}
                />
                <label htmlFor="DureeJour">Duree Jour</label>
                <input
                    type="text"
                    className="form-control"
                    id="Mail"
                    value={currentEmploye.DureeJour}
                    onChange={this.onChangeDureeJour}
                />
                <label htmlFor="DureeDimanche">Duree Dimanche</label>
                <input
                    type="text"
                    className="form-control"
                    id="Mail"
                    value={currentEmploye.DureeDimanche}
                    onChange={this.onChangeDureeDimanche}
                />

                <label htmlFor="DureeDimanche">Duree Totale</label>
                <input
                    type="text"
                    className="form-control"
                    id="Mail"
                    value={currentEmploye.DureeTotal}
                    onChange={this.onChangeDureeTotal}
                />
              </div>
              <div>

                <div className="alert alert-light" role="alert">
                  {message}
                </div>


              </div>

              <div className="form-group">
                <label>
                  <strong>Status:</strong>
                </label>
                {currentEmploye.published ? "Published" : "En cours de modification"}
              </div>
            </form>

          </div>

            <button
              className="badge badge-danger mr-2"
              onClick={this.removeEmploye}
            >
              Supprimer
            </button>
            <a
                className="btn badge-success"
                onClick={this.updateEmploye}
            >
              Mettre à jour
            </a>
          </div>

            </div>   )}
            {currentEmploye.published===true && (
                <div>
                  <a
                      href="https://pointage.ansart-tp.com/employes"
                  >
                    Revenir à la liste des employes
                  </a>

                </div>)}

          </div>

        ) : (
          <div>
            <br />
            <p>Erreur cet employe n'éxiste pas ou n'est pas dans la base de données</p>
            <a
                href="https://pointage.ansart-tp.com/employes"
            >
              Revenir à la liste des employés
            </a>
          </div>
        )
        }
      </div>

    );
  }
}

export default connect(null, { updateEmploye, deleteEmploye })(Employe);
