import React, { Component } from "react";
import { connect } from "react-redux";
import {createEmployes} from "../actions/employe";
import EmployesDataService from "../services/employe.service";

class AddEmploye extends Component {
  constructor(props) {
    super(props);
    this.onChangeNom = this.onChangeNom.bind(this);
    this.onChangePrenom=this.onChangePrenom.bind(this);
    this.onChangeEmployeur=this.onChangeEmployeur.bind(this);
    this.onChangeMail = this.onChangeMail.bind(this);
    this.onChangeDureeD = this.onChangeDureeD.bind(this);
    this.onChangeDureeN = this.onChangeDureeN.bind(this);
    this.onChangeChantier = this.onChangeChantier.bind(this);
    this.onChangeDureeJ = this.onChangeDureeJ.bind(this);
    this.onChangeNationalite=this.onChangeNationalite.bind(this);
    this.onChangeNaissance=this.onChangeNaissance.bind(this);
    this.newEmploye = this.newEmploye.bind(this);
    this.saveEmploye=this.saveEmploye.bind(this);

    this.state = {
      id: null,
      Nom: "",
      Prenom:"",
      Employeur: "",
      Mail:"",
      Naissance:'',
      Nationalite:'',
      DureeDimanche:0,
      DureeNuit:0,
      DureeJour:0,
      DureeTotal:null,
      Chantier:'#Numero_de_chantier',
      submitted: false,
      message: "",

    };
  }



  onChangeNationalite(e) {
    this.setState({
      Nationalite:e.target.value,
    });
  }
  onChangeNaissance(e) {
    this.setState({
      Naissance:e.target.value,
    });
  }


  onChangeNom(e) {
    this.setState({
      Nom: e.target.value,
    });
  }

  onChangeMail(e) {
    this.setState({
      Mail: e.target.value,
    });
  }

  onChangePrenom(e) {
    this.setState({
      Prenom: e.target.value,
    });
  }

  onChangeEmployeur(e) {
    this.setState({
      Employeur: e.target.value,
    });
  }

  onChangeDureeD(e) {
    this.setState({
      DureeDimanche: e.target.value,
    });
  }

  onChangeDureeN(e) {
    this.setState({
      DureeNuit: e.target.value,
    });
  }

  onChangeDureeJ(e) {
    this.setState({
      DureeJour: e.target.value,
    });
  }

  onChangeChantier(e) {
    this.setState({
      Chantier: e.target.value,
    });
  }

  newEmploye() {
    this.setState({

      id: null,
      Nom: "",
      Prenom: "",
      Employeur: "",
      Mail:"",
      Naissance:'',
      Nationalite:'',
      Chantier:"",
      DureeJour:null,
      DureeNuit:null,
      DureeTotal:null,
      DureeDimanche:null,

    });
  }

    saveEmploye() {
      const data = {
        Nom: this.state.Nom,
        Prenom: this.state.Prenom,
        Employeur: this.state.Employeur,
        Mail: this.state.Mail,
        Naissance:this.state.Naissance,
        Nationalite: this.state.Nationalite,
        DureeNuit:this.state.DureeNuit,
        DureeJour: this.state.DureeJour,
        DureeDimanche: this.state.DureeDimanche,
        DureeTotal: parseInt(this.state.DureeDimanche) + parseInt(this.state.DureeJour) + parseInt(this.state.DureeNuit),
        Chantier: this.state.Chantier,

      };
      EmployesDataService.create(data)
          .then(response => {
            this.setState({
              id: response.data.id,
              Nom: response.data.Nom,
              Prenom: response.data.Prenom,
              Employeur: response.data.Employeur,
              Mail:response.data.Mail,
              Naissance:response.data.Naissance,
              Nationalite:response.data.Nationalite,
              Chantier:response.data.Chantier,
              DureeDimanche:response.data.DureeDimanche,
              DureeNuit:response.data.DureeNuit,
              DureeTotal:response.data.DureeTotal,
              DureeJour:response.data.DureeJour,
              published: response.data.published,

              submitted: true
            });
            console.log(response.data);
          })
          .catch(e => {
            console.log(e);
          });
    }



  render() {
    const {message
    } = this.state;

    console.log(this.state.Mail);

    return (
        <div>
        <div>

          <div className="alert alert-light" role="alert">
            <p>{message}</p>
          </div>
        </div>

    <div className="submit-form">
      {this.state.submitted ? (
          <div>
            <h4>L'ajout a été effectué</h4>
            <a className="btn btn-success" onClick={this.newEmploye} href="https://pointage.ansart-tp.com/employes">
              Revenir à la liste des employés
            </a>
          </div>
      ) : (
          <div>
            <div className="form-group">
              <label htmlFor="Nom">Nom </label>
              <input
                  type="text"
                  className="form-control"
                  id="Nom"
                  required
                  value={this.state.Nom}
                  onChange={this.onChangeNom}
                  name="Nom"
              />
              <label htmlFor="Prenom">Prenom</label>
              <input
                  type="text"
                  className="form-control"
                  id="Prenom"
                  required
                  value={this.state.Prenom}
                  onChange={this.onChangePrenom}
                  name="Prenom"
              />

              <label htmlFor="Naissance">Date de naissance</label>
              <input
                  type="text"
                  className="form-control"
                  id="Naissance"
                  required
                  value={this.state.Naissance}
                  onChange={this.onChangeNaissance}
                  name="Naissance"
              />

              <label htmlFor="Nationalite">Nationalité</label>
              <input
                  type="text"
                  className="form-control"
                  id="Nationalite"
                  required
                  value={this.state.Nationalite}
                  onChange={this.onChangeNationalite}
                  name="Prenom"
              />

              <label htmlFor="Prenom">Duree Dimanche</label>
              <input
                  type="text"
                  className="form-control"
                  id="DureeD"
                  required
                  value={this.state.DureeDimanche}
                  onChange={this.onChangeDureeD}
                  name="Duree"
              />

              <label htmlFor="Prenom">Duree Jour</label>
              <input
                  type="text"
                  className="form-control"
                  id="DureeJ"
                  required
                  value={this.state.DureeJour}
                  onChange={this.onChangeDureeJ}
                  name="DureeJ"
              />

              <label htmlFor="Prenom">Duree Nuit</label>
              <input
                  type="text"
                  className="form-control"
                  id="DureeN"
                  required
                  value={this.state.DureeNuit}
                  onChange={this.onChangeDureeN}
                  name="DureeN"
              />

              <label htmlFor="Prenom">Chantier actuel</label>
              <input
                  type="text"
                  className="form-control"
                  id="DureeN"
                  required
                  value={this.state.Chantier}
                  onChange={this.onChangeChantier}
                  name="Chantier"
              />
              <label htmlFor="Employeur">Employeur</label>
              <input
                  type="text"
                  className="form-control"
                  id="Nom"
                  required
                  value={this.state.Employeur}
                  onChange={this.onChangeEmployeur}
                  name="Employeur"
              />

              <label htmlFor="Mail">Mail (non obligatoire) </label>
              <input
                  type="text"
                  className="form-control"
                  id="Mail"
                  value={this.state.Mail}
                  onChange={this.onChangeMail}
                  name="Mail"
              />
            </div>

            <button onClick={this.saveEmploye} className="btn btn-success">
              Ajouter
            </button>
          </div>
      )}
    </div>

        </div>
    );
  }
}


export default connect(null, { createEmployes })(AddEmploye);


