import {
  CREATE_THEME,
  RETRIEVE_THEMES,
  UPDATE_THEME,
  DELETE_THEME,
  DELETE_ALL_THEMES
} from "../actions/types";

const initialState = [];

function themeReducer(themes = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case CREATE_THEME:
      return [...themes, payload];

    case RETRIEVE_THEMES:
      return payload;

    case UPDATE_THEME:
      return themes.map((theme) => {
        if (theme.id === theme.id) {
          return {
            ...theme,
            ...payload,
          };
        } else {
          return theme;
        }
      });

    case DELETE_THEME:
      return themes.filter(({ id }) => id !== payload.id);

    case DELETE_ALL_THEMES:
      return [];

    default:
      return themes;
  }
}

export default themeReducer;
