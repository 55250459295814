import React, { Component } from "react";
import { connect } from "react-redux";
import { updateDocument, deleteDocument } from "../actions/document";
import DocumentsDataService from "../services/document.service";

class Document extends Component {
  constructor(props) {
    super(props);
    this.onChangeNom = this.onChangeNom.bind(this);

    this.getDocument = this.getDocument.bind(this);
    this.removeDocument = this.removeDocument.bind(this);
    this.updateDocument=this.updateDocument.bind(this);
    this.updatePublished=this.updatePublished.bind(this);

    this.state = {
      currentDocument: {
        id: null,
        Nom: "",

        published:false
      },

    };
  }

  componentDidMount() {
    this.getDocument(this.props.match.params.id);
  }

  onChangeNom(e) {
    const Nom = e.target.value;

    this.setState(function (prevState) {
      return {
        currentDocument: {
          ...prevState.currentDocument,
          Nom: Nom,
        },
      };
    });
  }




  updatePublished(status) {
    var data = {
      id: this.state.currentDocument.id,
      Nom: this.state.currentDocument.Nom,

    };

    DocumentsDataService.update(
        this.state.currentDocument.id,
        data
    )
        .then(response => {
          this.setState(prevState => ({
            currentDocument: {
              ...prevState.currentDocument,
              published: true
            }
          }));
          console.log(response.data);
        })
        .catch(e => {
          console.log(e);
        });
  }

  updateDocument() {
    DocumentsDataService.update(
        this.state.currentDocument.id,
        this.state.currentDocument
    )
        .then(response => {
          console.log(response.data);
          this.setState({
            message: "La mise à jour a été effectué",
          });
        }).then(response => {
      this.setState(prevState => ({
        currentDocument: {
          ...prevState.currentDocument,
          published: true
        }
      }));
      console.log(response.data);
    })
        .catch(e => {
          console.log(e);
        });
  }

  getDocument(id) {
    DocumentsDataService.get(id)
      .then((response) => {
        this.setState({
          currentDocument: response.data,
        });
        console.log(response.data);
      })
      .catch((e) => {
        console.log(e);
      });
  }



  removeDocument() {
    this.props
      .deleteDocument(this.state.currentDocument.id)
      .then(() => {
        this.props.history.push("/documents");
      })
      .catch((e) => {
        console.log(e);
      });
  }

  render() {
    const { currentDocument,message } = this.state;

    return (
      <div>
        {currentDocument ? (
            <div>
              {!currentDocument.published && (
            <div>
          <div className="edit-form">
            <div>
            <h4>Mise à jour de : {currentDocument.Nom}, {currentDocument.Prenom}</h4>
            <form>
              <div className="form-group">
                <label htmlFor="Nom">Nom</label>
                <input
                    type="text"
                    className="form-control"
                    id="Nom"
                    value={currentDocument.Nom}
                    onChange={this.onChangeNom}
                />


              </div>
              <div>

                <div className="alert alert-light" role="alert">
                  {message}
                </div>


              </div>

              <div className="form-group">
                <label>
                  <strong>Status:</strong>
                </label>
                {currentDocument.published ? "Published" : "En cours de modification"}
              </div>
            </form>

          </div>

            <button
              className="badge badge-danger mr-2"
              onClick={this.removeDocument}
            >
              Supprimer
            </button>
            <a
                className="btn badge-success"
                onClick={this.updateDocument}
            >
              Mettre à jour
            </a>
          </div>

            </div>   )}
            {currentDocument.published===true && (
                <div>
                  <a
                      href="https://pointage.ansart-tp.com/documents/"
                  >
                    Revenir à la liste des documents
                  </a>

                </div>)}

          </div>

        ) : (
          <div>
            <br />
            <p>Erreur ce document n'éxiste pas ou n'est pas dans la base de données</p>
            <a
                href="https://pointage.ansart-tp.com/documents/"
            >
              Revenir à la liste des documents
            </a>
          </div>
        )
        }
      </div>

    );
  }
}

export default connect(null, {updateDocument, deleteDocument  })(Document);
