import {
CREATE_DOCUMENT,
RETRIEVE_DOCUMENTS,
UPDATE_DOCUMENT,
DELETE_DOCUMENT,
DELETE_ALL_DOCUMENTS
} from "./types";

import DocuementsDataService from "../services/document.service";

export const createDocuments = (Nom) => async (dispatch) => {
  try {
    const res = await DocuementsDataService.create({ Nom});

    dispatch({
      type: CREATE_DOCUMENT,
      payload: res.data,
    });

    return Promise.resolve(res.data);
  } catch (err) {
    return Promise.reject(err);
  }
};


export const retrieveDocuments = () => async (dispatch) => {

  try {
    const res = await DocuementsDataService.getAll();

    dispatch({
      type: RETRIEVE_DOCUMENTS,
      payload: res.data,

    });
  } catch (err) {
    console.log(err);
  }
};

export const updateDocument = (id, data) => async (dispatch) => {
  try {
    const res = await DocuementsDataService.update(id, data);

    dispatch({
      type: UPDATE_DOCUMENT,
      payload: data,
    });

    return Promise.resolve(res.data);
  } catch (err) {
    return Promise.reject(err);
  }
};

export const deleteDocument = (id) => async (dispatch) => {
  try {
    await DocuementsDataService.delete(id);

    dispatch({
      type: DELETE_DOCUMENT,
      payload: { id },
    });
  } catch (err) {
    console.log(err);
  }
};

export const deleteAllDocuments = () => async (dispatch) => {
  try {
    const res = await DocuementsDataService.deleteAll();

    dispatch({
      type: DELETE_ALL_DOCUMENTS,
      payload: res.data,
    });

    return Promise.resolve(res.data);
  } catch (err) {
    return Promise.reject(err);
  }
};

export const findDocumentsByTitle = (nom) => async (dispatch) => {
  try {
    const res = await DocuementsDataService.findByTitle(nom);

    dispatch({
      type: RETRIEVE_DOCUMENTS,
      payload: res.data
    });
  } catch (err) {
    console.log(err);
  }
};
