import {
CREATE_MATERIEL,
RETRIEVE_MATERIELS,
UPDATE_MATERIEL,
DELETE_MATERIEL,
DELETE_ALL_MATERIELS
} from "./types";

import MaterielsDataService from "../services/materiel.service";

export const createMateriel = (Nom,Entreprise) => async (dispatch) => {
  try {
    const res = await MaterielsDataService.create({ Nom,Entreprise});

    dispatch({
      type: CREATE_MATERIEL,
      payload: res.data,
    });

    return Promise.resolve(res.data);
  } catch (err) {
    return Promise.reject(err);
  }
};


export const retrieveMateriels = () => async (dispatch) => {

  try {
    const res = await MaterielsDataService.getAll();

    dispatch({
      type: RETRIEVE_MATERIELS,
      payload: res.data,

    });
  } catch (err) {
    console.log(err);
  }
};

export const updateMateriel = (id, data) => async (dispatch) => {
  try {
    const res = await MaterielsDataService.update(id, data);

    dispatch({
      type: UPDATE_MATERIEL,
      payload: data,
    });

    return Promise.resolve(res.data);
  } catch (err) {
    return Promise.reject(err);
  }
};

export const deleteMateriel = (id) => async (dispatch) => {
  try {
    await MaterielsDataService.delete(id);

    dispatch({
      type: DELETE_MATERIEL,
      payload: { id },
    });
  } catch (err) {
    console.log(err);
  }
};

export const deleteAllMateriels = () => async (dispatch) => {
  try {
    const res = await MaterielsDataService.deleteAll();

    dispatch({
      type: DELETE_ALL_MATERIELS,
      payload: res.data,
    });

    return Promise.resolve(res.data);
  } catch (err) {
    return Promise.reject(err);
  }
};

export const findMaterielsByTitle = (nom) => async (dispatch) => {
  try {
    const res = await MaterielsDataService.findByTitle(nom);

    dispatch({
      type: RETRIEVE_MATERIELS,
      payload: res.data
    });
  } catch (err) {
    console.log(err);
  }
};
