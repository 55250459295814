import axios from "axios";
const user = JSON.parse(localStorage.getItem('user'));
if(user) {
  var tolken=user.accessToken;
}
else{
  var tolken=null;
}

export default axios.create({
  baseURL: "https://api.attirail.fr/api",
  headers: {
    "Content-type": "application/json",
    "x-access-token": tolken,
  }

});
