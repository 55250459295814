import React, { Component } from "react";
import { connect } from "react-redux";
import { updateMateriel, deleteMateriel } from "../actions/materiel";
import MaterielsDataService from "../services/materiel.service";

class Materiel extends Component {
  constructor(props) {
    super(props);
    this.onChangeNom = this.onChangeNom.bind(this);
    this.onChangeProvenance=this.onChangeProvenance.bind(this);

    this.getMateriel = this.getMateriel.bind(this);
    this.removeMateriel = this.removeMateriel.bind(this);
    this.updateMateriel=this.updateMateriel.bind(this);
    this.updatePublished=this.updatePublished.bind(this);

    this.state = {
      currentMateriel: {
        id: null,
        Nom: "",
        Entreprise:"",
        published:false
      },

    };
  }

  componentDidMount() {
    this.getMateriel(this.props.match.params.id);
  }

  onChangeNom(e) {
    const Nom = e.target.value;

    this.setState(function (prevState) {
      return {
        currentMateriel: {
          ...prevState.currentMateriel,
          Nom: Nom,
        },
      };
    });
  }

  onChangeProvenance(e) {
    const Entreprise = e.target.value;

    this.setState(function (prevState) {
      return {
        currentMateriel: {
          ...prevState.currentMateriel,
          Entreprise: Entreprise,
        },
      };
    });
  }

  updatePublished(status) {
    var data = {
      id: this.state.currentMateriel.id,
      Nom: this.state.currentMateriel.Nom,
      Entreprise: this.state.currentMateriel.Entreprise,
      published: status
    };

    MaterielsDataService.update(
        this.state.currentMateriel.id,
        data
    )
        .then(response => {
          this.setState(prevState => ({
            currentMateriel: {
              ...prevState.currentMateriel,
              published: status
            }
          }));
          console.log(response.data);
        })
        .catch(e => {
          console.log(e);
        });
  }

  updateMateriel() {
    MaterielsDataService.update(
        this.state.currentMateriel.id,
        this.state.currentMateriel
    )
        .then(response => {
          console.log(response.data);
          this.setState({
            message: "Modification réussi!"
          });
        }).then(response => {
      this.setState(prevState => ({
        currentMateriel: {
          ...prevState.currentMateriel,
          published: true
        }
      }));
      console.log(response.data);
    })
        .catch(e => {
          console.log(e);
        });
  }

  getMateriel(id) {
    MaterielsDataService.get(id)
      .then((response) => {
        this.setState({
          currentMateriel: response.data,
        });
        console.log(response.data);
      })
      .catch((e) => {
        console.log(e);
      });
  }





  removeMateriel() {
    this.props
      .deleteMateriel(this.state.currentMateriel.id)
      .then(() => {
        this.props.history.push("/materiels");
      })
      .catch((e) => {
        console.log(e);
      });
  }

  render() {
    const { currentMateriel,message } = this.state;

    return (
      <div>
        {currentMateriel ? (
            <div>
              {!currentMateriel.published && (
            <div>
          <div className="edit-form">
            <div>
            <h4>Mise à jour de : {currentMateriel.Nom}</h4>
            <form>
              <div className="form-group">
                <label htmlFor="Nom">Nom</label>
                <input
                    type="text"
                    className="form-control"
                    id="Nom"
                    value={currentMateriel.Nom}
                    onChange={this.onChangeNom}
                />

                <label htmlFor="Provenance">Provenance</label>
                <input
                    type="text"
                    className="form-control"
                    id="Provenance"
                    value={currentMateriel.Entreprise}
                    onChange={this.onChangeProvenance}
                />
              </div>
              <div>

                <div className="alert alert-light" role="alert">
                  {message}
                </div>


              </div>

              <div className="form-group">
                <label>
                  <strong>Status:</strong>
                </label>
                {currentMateriel.published ? "Published" : "En cours de modification"}
              </div>
            </form>

          </div>

            <button
              className="badge badge-danger mr-2"
              onClick={this.removeMateriel}
            >
              Supprimer
            </button>
            <a
                className="btn badge-success"
                onClick={this.updateMateriel}
            >
              Mettre à jour
            </a>
          </div>

            </div>   )}
            {currentMateriel.published===true && (
                <div>
                  <a
                      href="https://pointage.ansart-tp.com/materiels/"
                  >
                    Revenir à la liste des materiels
                  </a>

                </div>)}

          </div>

        ) : (
          <div>
            <br />
            <p>Erreur ce matériel n'éxiste pas ou n'est pas dans la base de données</p>
            <a
                href="https://pointage.ansart-tp.com/materiels/"
            >
              Revenir à la liste des materiels
            </a>
          </div>
        )
        }
      </div>

    );
  }
}

export default connect(null, {  updateMateriel, deleteMateriel })(Materiel);
