import {
CREATE_THEME,
RETRIEVE_THEMES,
UPDATE_THEME,
DELETE_THEME,
DELETE_ALL_THEMES
} from "./types";

import ThemesDataService from "../services/theme.service";

export const createThemes = (Nom) => async (dispatch) => {
  try {
    const res = await ThemesDataService.create({ Nom});

    dispatch({
      type: CREATE_THEME,
      payload: res.data,
    });

    return Promise.resolve(res.data);
  } catch (err) {
    return Promise.reject(err);
  }
};


export const retrieveThemes = () => async (dispatch) => {

  try {
    const res = await ThemesDataService.getAll();

    dispatch({
      type: RETRIEVE_THEMES,
      payload: res.data,

    });
  } catch (err) {
    console.log(err);
  }
};

export const updateTheme = (id, data) => async (dispatch) => {
  try {
    const res = await ThemesDataService.update(id, data);

    dispatch({
      type: UPDATE_THEME,
      payload: data,
    });

    return Promise.resolve(res.data);
  } catch (err) {
    return Promise.reject(err);
  }
};

export const deleteTheme = (id) => async (dispatch) => {
  try {
    await ThemesDataService.delete(id);

    dispatch({
      type: DELETE_THEME,
      payload: { id },
    });
  } catch (err) {
    console.log(err);
  }
};

export const deleteAllThemes = () => async (dispatch) => {
  try {
    const res = await ThemesDataService.deleteAll();

    dispatch({
      type: DELETE_ALL_THEMES,
      payload: res.data,
    });

    return Promise.resolve(res.data);
  } catch (err) {
    return Promise.reject(err);
  }
};

export const findThemesByTitle = (nom) => async (dispatch) => {
  try {
    const res = await ThemesDataService.findByTitle(nom);

    dispatch({
      type: RETRIEVE_THEMES,
      payload: res.data
    });
  } catch (err) {
    console.log(err);
  }
};
