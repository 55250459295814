import {
CREATE_EMPLOYE,
RETRIEVE_EMPLOYES,
UPDATE_EMPLOYE,
DELETE_EMPLOYE,
DELETE_ALL_EMPLOYES
} from "./types";

import EmployesDataService from "../services/employe.service";

export const createEmployes = (Nom,Prenom,Employeur,Mail,Naissance,Nationalite,DureeNuit,DureeJour,DureeDimanche,DureeTotal,Chantier) => async (dispatch) => {

  try {
    const res = await EmployesDataService.create({ Nom,Prenom,Employeur,Mail,Naissance,Nationalite,DureeNuit,DureeJour,DureeDimanche,DureeTotal,Chantier});
    dispatch({
      type: CREATE_EMPLOYE,
      payload: res.data,
    });

    return Promise.resolve(res.data);
  } catch (err) {
    return Promise.reject(err);
  }
};


export const retrieveEmployes = () => async (dispatch) => {

  try {
    const res = await EmployesDataService.getAll();

    dispatch({
      type: RETRIEVE_EMPLOYES,
      payload: res.data,

    });
  } catch (err) {
    console.log(err);
  }
};

export const updateEmploye = (id, data) => async (dispatch) => {
  try {
    const res = await EmployesDataService.update(id, data);

    dispatch({
      type: UPDATE_EMPLOYE,
      payload: data,
    });

    return Promise.resolve(res.data);
  } catch (err) {
    return Promise.reject(err);
  }
};

export const deleteEmploye = (id) => async (dispatch) => {
  try {
    await EmployesDataService.delete(id);

    dispatch({
      type: DELETE_EMPLOYE,
      payload: { id },
    });
  } catch (err) {
    console.log(err);
  }
};

export const deleteAllEmployes = () => async (dispatch) => {
  try {
    const res = await EmployesDataService.deleteAll();

    dispatch({
      type: DELETE_ALL_EMPLOYES,
      payload: res.data,
    });

    return Promise.resolve(res.data);
  } catch (err) {
    return Promise.reject(err);
  }
};

export const findEmployesByTitle = (nom) => async (dispatch) => {
  try {
    const res = await EmployesDataService.findByTitle(nom);

    dispatch({
      type: RETRIEVE_EMPLOYES,
      payload: res.data
    });
  } catch (err) {
    console.log(err);
  }
};
